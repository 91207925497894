.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.hover-pointer:hover
{
  cursor: pointer;
}

.icon
{
  font-size:24px;
  color:#002266;
}

#rotate
{
  display: inline-block;
  transform: rotateZ(90deg);
}

#text
{
  text-align: justify;
  text-justify: inter-word;
}

.navleft
{
  height: 100%;  width: 1%;  position: fixed; z-index: 1; top: 0; left: 0; background-color:red; transition: .5s ease; overflow-x: hidden; padding-top: 5px; display: flex; flex-direction: column; align-items: center;
}

.navtop
{
  height: 1%;  width: 100%;  position: fixed; z-index: 1; top: 0; left: 0; background-color:red; transition: .5s ease; overflow-x: hidden; padding-top: 10px; display: flex; flex-direction: row; align-items: center;
}

.timeline {
  margin: 50px 60px;
  height: 4px;
  background-color: #ccc;
}

.timeline-progress {
	width: 0;
	height: 100%;
	background-color: black;
}

.timeline-items {
	margin-left: -10px;
	margin-right: -10px;
	margin-top: -12px;
	display: flex;
	justify-content: space-between;
}

.timeline-item {
	position: relative;
}

.timeline-item::before {
	content: '';
	width: 20px;
	height: 20px;
	background-color: #ccc;
	display: block;
	border-radius: 100%;
}

.timeline-content {
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ddd;
	width: 100px;
	padding: 5px 10px;
	border-radius: 5px;
	text-align: center;
}

.timeline-content::before {
	content: '';
	width: 10px;
	height: 10px;
	background-color: #ddd;
	display: block;
	position: absolute;
	top: -8px;
	margin-left: -2px;
	left: 50%;
	transform: rotate(-45deg) translateX(-50%);
}

.timeline-item.active::before {
	background-color: black;
}

.timeline-item.active .timeline-content {
	color: #fff;
	background-color: black;
}

.timeline-item.active .timeline-content::before {
	background-color: black;
}

.navbar-toggler-icon {
  color: #00008b; /* Your desired color */
}
